import { render, staticRenderFns } from "./CustomerAdsReport.vue?vue&type=template&id=55f54b31&scoped=true&"
import script from "./CustomerAdsReport.vue?vue&type=script&lang=js&"
export * from "./CustomerAdsReport.vue?vue&type=script&lang=js&"
import style0 from "./CustomerAdsReport.vue?vue&type=style&index=0&id=55f54b31&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f54b31",
  null
  
)

export default component.exports